import React, { Component } from "react";
import Input from "./Input";
import Row from "./Row";

export default class WidgetBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      primary: "#3b2774",
      secondary: "#4bbcbc",
      gray: "#ebe7f1",
      text: "#000",
      origin: window.location.origin
    };
  }

  handleInput = e => {
    let { colors } = this.state;
    console.log(e);
    colors[e.target.name] = e.target.value;
    this.setState({ colors });
  };

  componentDidMount() {
    this.setState({
      ...this.props.colors
    });
  }

  componentDidUpdate(prev) {
    if (prev !== this.props) {
      this.setState({ ...this.props.colors });
    }
  }

  generateCode = () => {
    let { primary, secondary, text, gray } = this.state;

    this.props.onSave({
      primary,
      secondary,
      text,
      gray
    });
  };

  render() {
    let { primary, secondary, text, gray } = this.state;
    return (
      <>
        <Row>
          <Input
            type="color"
            style={{ width: "calc(100% - 30px)", height: 120 }}
            label={"Hoofd"}
            value={primary}
            onChange={e => this.setState({ primary: e.target.value })}
          />
          <Input
            type="color"
            style={{ width: "calc(100% - 30px)", height: 120 }}
            label={"Secundaire"}
            value={secondary}
            onChange={e => this.setState({ secondary: e.target.value })}
          />
          <Input
            type="color"
            style={{ width: "calc(100% - 30px)", height: 120 }}
            label={"Text"}
            value={text}
            onChange={e => this.setState({ text: e.target.value })}
          />
          <Input
            type="color"
            style={{ width: "calc(100% - 30px)", height: 120 }}
            label={"Grijs"}
            value={gray}
            onChange={e => this.setState({ gray: e.target.value })}
          />
        </Row>
        <div onClick={this.generateCode} className="btn btn-primary">
          Genereer code
        </div>
      </>
    );
  }
}
