import React, { Component } from "react";
import Input from "../Input";

const InputRow = props => {
  return (
    <div className="input-row">
      <span>{props.label}</span>
      <div className="input-row-wrapper">
        <span className="badge">{props.badge}</span>
        {props.custom || <input {...props} />}
      </div>
    </div>
  );
};

export default InputRow;
