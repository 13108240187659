import React from "react";

export default function Pagination({
  prev,
  next,
  total,
  current_page,
  last_page
}) {
  return (
    <div className="pagination">
      <div className="total">
        <span>Total: {total}</span>
      </div>
      <div className="arrows">
        {current_page !== 1 ? (
          <div onClick={prev} className="arrow">
            <i className="fal fa-angle-left" />
          </div>
        ) : (
          false
        )}
        <span className="current">
          {current_page} / {last_page}
        </span>

        {current_page !== last_page ? (
          <div onClick={next} className="arrow">
            <i className="fal fa-angle-right" />
          </div>
        ) : (
          false
        )}
      </div>
    </div>
  );
}
