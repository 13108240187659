import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

import Card from "../Card";
import Input from "../Input";

import { updateUser } from "../../redux/user";

export class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      repeat: "",
      password: "",
      loading: false
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  changePassword = async () => {
    this.setState({ loading: true });
    let { repeat, password } = this.state;
    if (repeat === "" || password === "" || repeat !== password) {
      toast.warning("Wachtwoorden komen niet overeen", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      this.setState({ loading: false });
      return;
    }

    let user = await this.props.updateUser({ password });
    if (user) {
      toast.info(`Succesvol aangepast`, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      this.setState({
        loading: false,
        password: "",
        repeat: ""
      });
      return;
    }
    toast.warning("Er is iets misgegaan", {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    this.setState({ loading: false });
  };

  render() {
    const { repeat, password, loading } = this.state;

    return (
      <Card {...{ loading }}>
        <ToastContainer />
        <p className="card__title">Verander wachtwoord</p>
        <Input
          label="Nieuw wachtwoord"
          type="password"
          name="password"
          onChange={this.handleChange}
          value={password}
          placeholder=""
        />
        <Input
          label="Herhaal wachtwoord"
          type="password"
          name="repeat"
          onChange={this.handleChange}
          value={repeat}
          placeholder=""
        />
        <div className="buttons">
          <button
            onClick={() => this.changePassword()}
            className="btn btn-primary"
          >
            Aanpassen
          </button>
        </div>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUser: bindActionCreators(updateUser, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
