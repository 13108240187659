import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUser } from "./redux/user";

//Import views
import LoginView from "./views/Auth/LoginView";
import IntroView from "./views/Auth/IntroView";
import ResetPasswordView from "./views/Auth/ResetPasswordView";

import RelationIndexView from "./views/Relations/IndexView";
import RelationCreateView from "./views/Relations/CreateView";
import RelationCreateUserView from "./views/Relations/Users/CreateView";

import RequestIndexView from "./views/Request/IndexView";
import RequestShowView from "./views/Request/ShowView";

import UsersIndexView from "./views/Users/IndexView";

import ProfileShowView from "./views/Profile/ShowView";
import IframeView from "./views/IframeView";
import CalculatorView from "./views/CalculatorView";

import DashboardView from "./views/Dashboard/DashboardView";
import PdfView from "./views/Dashboard/PdfView";

import SettingsView from "./views/Settings/SettingsView";

import ConnectionsView from "./views/Connections/IndexView";
import ConnectionsCreateView from "./views/Connections/CreateView";

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
	<Route
		{...rest}
		auth={auth}
		render={(props) =>
			auth ? <Component {...props} /> : <Redirect to="/" />
		}
	/>
);

const AdminRoute = ({ component: Component, auth, ...rest }) => (
	<Route
		{...rest}
		auth={auth}
		render={(props) =>
			auth && auth.admin ? (
				<Component {...props} />
			) : (
				<Redirect to="/requests" />
			)
		}
	/>
);

class AppRouter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		};
	}

	async componentDidMount() {
		let user = await this.props.getUser();
		this.setState({
			loading: false,
		});
	}

	render() {
		let { loading } = this.state;
		let { user } = this.props;
		let auth = user.data;

		if (loading) {
			return <IntroView />;
		}

		return (
			<Router>
				<div style={{ width: "100%" }}>
					<Route path="/" exact component={LoginView} />
					<Route path="/login" exact component={LoginView} />
					<Route
						path="/change/:token?"
						component={ResetPasswordView}
						exact
					/>

					<Route
						path="/calculator/:id"
						exact
						component={IframeView}
					/>
					<Route path="/calculator" exact component={IframeView} />
					<PrivateRoute
						exact
						auth={auth}
						path="/dashboard"
						component={DashboardView}
					/>
					<PrivateRoute
						exact
						auth={auth}
						path="/dashboard/instructions"
						component={PdfView}
					/>
					<PrivateRoute
						exact
						auth={auth}
						path="/connections"
						component={ConnectionsView}
					/>
					<PrivateRoute
						exact
						auth={auth}
						path="/connections/create"
						component={ConnectionsCreateView}
					/>
					<PrivateRoute
						exact
						auth={auth}
						path="/connections/:id/edit"
						component={ConnectionsCreateView}
					/>
					<PrivateRoute
						exact
						auth={auth}
						path="/customize"
						component={RelationCreateView}
					/>
					<AdminRoute
						auth={auth}
						path="/relations"
						exact
						component={RelationIndexView}
					/>
					<AdminRoute
						exact
						auth={auth}
						path="/relations/create"
						component={RelationCreateView}
					/>
					<AdminRoute
						auth={auth}
						exact
						path="/relations/:id/edit"
						component={RelationCreateView}
					/>
					<AdminRoute
						exact
						auth={auth}
						path="/relations/:id/edit/users"
						component={RelationCreateUserView}
					/>
					<PrivateRoute
						exact
						auth={auth}
						path="/requests"
						component={RequestIndexView}
					/>
					<PrivateRoute
						exact
						auth={auth}
						path="/requests/:id"
						component={RequestShowView}
					/>
					<AdminRoute
						exact
						auth={auth}
						path="/users"
						component={UsersIndexView}
					/>
					<AdminRoute
						exact
						auth={auth}
						path="/settings"
						component={SettingsView}
					/>
					<PrivateRoute
						exact
						auth={auth}
						path="/profile"
						component={ProfileShowView}
					/>
				</div>
			</Router>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
});

function mapDispatchToProps(dispatch) {
	return {
		getUser: bindActionCreators(getUser, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
