import axios from "axios";
const production = true;
let url = "";

if (!production) {
  global.mediaUrl = "http://127.0.0.1:8080/api";
  url = global.mediaUrl;
} else {
  global.mediaUrl = "https://autogeld-api.mwit-demo.nl/api";
  url = global.mediaUrl;
}

axios.defaults.baseURL = url;
axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  Accept: "application/json",
  "Content-Type": "application/json"
};

axios.interceptors.request.use(async config => {
  const newConfig = { ...config };
	try {
		const token = await localStorage.getItem("t");
		if (token) {
			newConfig.headers.Authorization = `Bearer ${token}`;
		}
	} catch (error) {}
  return newConfig;
});

