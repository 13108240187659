import React from "react";

export default function Grid({ children, columns }) {
  return (
    <div
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: `repeat(${columns || 3}, 1fr)`
      }}
    >
      {children}
    </div>
  );
}
