import React, { Component } from "react";
import InputRow from "../InputRow";
import Select from "../../Select";
import Powered from "../Powered";

const PMT = (
	rate_per_period,
	number_of_payments,
	present_value,
	future_value,
	type
) => {
	future_value = typeof future_value !== "undefined" ? future_value : 0;
	type = typeof type !== "undefined" ? type : 0;

	if (rate_per_period !== 0.0) {
		// Interest rate exists
		var q = Math.pow(1 + rate_per_period, number_of_payments);
		return (
			-(rate_per_period * (future_value + q * present_value)) /
			((-1 + q) * (1 + rate_per_period * type))
		);
	} else if (number_of_payments !== 0.0) {
		// No interest rate, but number of payments exists
		return -(future_value + present_value) / number_of_payments;
	}

	return 0;
};

class Calculator extends Component {
	constructor(props) {
		super(props);
		this.state = {
			price:
				props.price && props.price !== "undefined"
					? props.price
					: 20000,
			slot: 0,
			slotValue: 4000,
			durationIndex: 5,
			options: [
				{ label: "12 maanden", months: 12, slot: 0.6, rent: 0.0699 },
				{ label: "24 maanden", months: 24, slot: 0.5, rent: 0.0699 },
				{ label: "36 maanden", months: 36, slot: 0.4, rent: 0.0699 },
				{ label: "48 maanden", months: 48, slot: 0.3, rent: 0.0699 },
				{ label: "60 maanden", months: 60, slot: 0.2, rent: 0.0699 },
				{ label: "72 maanden", months: 72, slot: 0.1, rent: 0.0699 },
			],
		};
	}

	componentDidMount() {
		let { price, options, durationIndex } = this.state;

		this.setState({
			slot: price * options[durationIndex].slot,
		});
		if (window.parent) {
			window.parent.postMessage(
				{
					func: "setIframeSize",
					message: {
						height: document.documentElement.scrollHeight,
					},
				},
				"*"
			);
		}
	}

	handle = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSlot = (e) => {
		let { durationIndex, options, price } = this.state;
		let slotValue = e.target.value;
		let option = options[durationIndex];

		if (slotValue > option.slot * price) {
			return;
		}

		let percent = slotValue / price;

		this.setState({
			slot: price * percent,
			slotValue,
		});
	};

	render() {
		let { durationIndex, options, price, slot, slotValue } = this.state;
		let { colors } = this.props;
		let option = options[durationIndex];

		let rate = option.rent / 12;
		let output = PMT(rate, option.months, -price, slot, 0);

		let percent = slotValue / price;

		if (percent >= option.slot) {
			slotValue = option.slot * price;
			slotValue = slotValue.toFixed(2);
		}

		if (output < 0) {
			output = 0;
		}

		return (
			<div
				style={{ color: colors.text }}
				className="calculator__box calculator__box__gray"
			>
				<h1>Financial Lease calculator</h1>
				<form>
					<InputRow
						type="number"
						name="price"
						onChange={this.handle}
						badge={"€"}
						label="Leasebedrag"
						value={price}
					/>
					<InputRow
						type="number"
						onChange={this.handleSlot}
						// max={option.slot}
						badge={"€"}
						label="Slottermijn"
						max={price * option.slot}
						// value={slot.toFixed(2)}
						value={slotValue}
					/>
					<InputRow
						name="duration"
						onChange={this.handle}
						badge={<i className="fal fa-calendar" />}
						label="Looptijd"
						custom={
							<Select
								options={options}
								value={durationIndex}
								onSelect={(durationIndex) =>
									this.setState({ durationIndex })
								}
							/>
						}
					/>

					<div
						style={{
							display: "flex",
							alignItems: "flex-end",
							justifyContent: "center",
							flexDirection: "column",
							padding: 10,
						}}
					>
						{output ? (
							<>
								<p className="cost">
									<b>&euro; {output.toFixed(2)}</b> p/m
								</p>
								<div
									onClick={() => {
										window.parent.postMessage(
											{
												func: "setIframeButton",
												message: {
													price: price || 0,
													thumbnail: "",
													model: "",
													duration:
														option.months || 12,
													slot: option.slot,
												},
											},
											"*"
										);
									}}
									data-model=""
									data-thumbnail=""
									data-duration={option.months || 12}
									data-price={price || 0}
									data-slot={option.slot || 0}
									style={{
										padding: "10px 15px",
										marginLeft: 10,
										backgroundColor: colors.primary,
									}}
									className="btn btn-primary js-button"
								>
									Vraag offerte aan
								</div>
							</>
						) : (
							false
						)}

						{!this.props.noLogo ? <Powered /> : false}
					</div>
				</form>
			</div>
		);
	}
}
export default Calculator;
