import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Editor from "../../components/Editor";
import Layout from "../../components/Layout";
import Card from "../../components/Card";
import Row from "../../components/Row";

import AddText from "../../components/forms/AddText";

export class SettingsView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			texts: {},
			loading: true,
		};
	}

	componentDidMount() {
		this.fetchTexts();
	}

	fetchTexts = async () => {
		this.setState({ loading: true });
		let { data } = await axios.get("/text");

		this.setState({
			loading: false,
			texts: data,
		});
	};

	save = async () => {
		let { texts } = this.state;
		this.setState({ loading: true });
		texts = Array.from(texts);

		let { data } = await axios.patch("/text", { texts });
		this.setState({ loading: false });
	};

	delete = async (id) => {
		let { data } = await axios.delete(`/text/${id}`);
		if (data.message) {
			this.fetchTexts();
		}
	};

	handleEditor = (key, value) => {
		let { texts } = this.state;
		texts[key].value = value;
		this.setState({ texts });
	};

	render() {
		let { loading, texts } = this.state;

		return (
			<Layout {...{ loading }}>
				<div className="layout__head">
					<h1>Instellingen</h1>
					<div className="buttons">
						<div
							onClick={() => this.save()}
							className="btn btn-primary"
						>
							Save
						</div>
					</div>
				</div>
				<div className="row-3">
					<Card>
						<AddText onSave={this.fetchTexts}></AddText>
					</Card>

					{Object.keys(texts).map((key) =>
						texts[key].type !== "calculator" ? (
							<Card
								key={texts[key].id + key}
								span={3}
								{...{ loading: false }}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									<p className="card__title">
										{texts[key].key} text
									</p>
									<div
										onClick={() =>
											this.delete(texts[key].id)
										}
										className="btn"
									>
										<i
											className="fal fa-trash"
											style={{ padding: 0 }}
										></i>
									</div>
								</div>
								<Editor
									onChange={this.handleEditor}
									name={key}
									value={texts[key].value}
								/>
							</Card>
						) : (
							false
						)
					)}
				</div>
			</Layout>
		);
	}
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView);
