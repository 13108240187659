import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./lib/api";
import Router from "./Router";
import "./dist/index.css";
import configureStore from "./redux";

export default class App extends Component {
  store = configureStore();
  render() {
    return (
      <Provider store={this.store}>
        <Router />
      </Provider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
