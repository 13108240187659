import axios from "axios";

export const FETCHING_USER = "FETCHING_USER";
export const FETCHING_USER_SUCCESS = "FETCHING_USER_SUCCESS";
export const FETCHING_USER_FAILURE = "FETCHING_USER_FAILURE";

const initialState = {
  data: undefined,
  dataFetched: false,
  isFetching: true,
  error: false
};

export default function cart(state = initialState, action) {
  switch (action.type) {
    case FETCHING_USER:
      return {
        ...state,
        isFetching: true
      };
    case FETCHING_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    case FETCHING_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true
      };
    default:
      return state;
  }
}

export function getData() {
  return {
    type: FETCHING_USER
  };
}

export function getDataSuccess(data) {
  return {
    type: FETCHING_USER_SUCCESS,
    data
  };
}

export function getDataFailure() {
  return {
    type: FETCHING_USER_FAILURE
  };
}

export function loginUser(email, password) {
  return dispatch => {
    dispatch(getData());
    return axios
      .post("/auth/login", {
        email,
        password
      })
      .then(response => {
        if (response.data) {
          localStorage.setItem("t", response.data.token);
          dispatch(getDataSuccess(response.data.user));
          return response.data.user;
        }
      })
      .catch(e => {
        return "error";
      });
  };
}

export function getUser() {
  return (dispatch, getState) => {
    dispatch(getData());
    return axios
      .get("/auth/me")
      .then(response => {
        if (response.data.id) {
          dispatch(getDataSuccess(response.data));
          return response.data;
        }
        return false;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  };
}

export function updateUser(user = {}) {
  return (dispatch, getState) => {
    dispatch(getData());
    return axios
      .patch("/auth/me", user)
      .then(response => {
        if (response.data.id) {
          dispatch(getDataSuccess(response.data));
          return response.data;
        }
        return false;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  };
}
