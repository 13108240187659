import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Layout from "../../components/Layout";
import Card from "../../components/Card";
import Input from "../../components/Input";
import Options from "../../components/Options";
import axios from "axios";

const TYPES = [
	{
		label: "Lef",
		value: "lef",
	},
];

const CreateView = (props) => {
	const [loading, setLoading] = useState(false);
	const [username, setUsername] = useState("");
	const [account, setAccount] = useState("");
	const [password, setPassword] = useState("");
	const [type, setType] = useState("lef");
	let id = props.match.params.id;

	useEffect(() => {
		if (id) {
			axios.get(`/connection/${id}`).then(({ data }) => {
				setUsername(data.username);
				setType(data.type);
				setAccount(data.account_number);
			});
		}
	}, []);

	const handleSubmit = () => {
		setLoading(true);
		if (!type || !username || (!password && !id)) {
			alert("Vul alle velden in");
			setLoading(false);
			return;
		}

		if (!type || (!username && id)) {
			alert("Vul alle velden in");
			setLoading(false);
			return;
		}

		const user = props.user.data;
		const relation =
			user && user.relation && user.relation.length
				? user.relation[0].id
				: null;
		let data = {
			username,
			password: password || null,
			account_number: account,
			type,
			relation_id: relation,
		};
		let action = id
			? axios.patch(`/connection/${id}`, data)
			: axios.post(`/connection`, data);
		action
			.then(() => {
				setLoading(false);
				props.history.push("/connections");
			})
			.catch(() => {
				alert("Er is iets mis gegaan, probeer het later opnieuw");
				setLoading(false);
			});
	};

	const handleDelete = () => {
		setLoading(true);
		axios
			.delete(`/connection/${id}`)
			.then(() => {
				props.history.push("/connections");
			})
			.catch(() => {
				setLoading(false);
			});
	};

	return (
		<Layout
			title={
				<>
					<h1>Nieuwe koppeling</h1>
					<div className="buttons">
						<div
							onClick={() => handleSubmit()}
							className="btn btn-primary"
						>
							{loading ? (
								<i className="fa fa-spin fa-spinner-third"></i>
							) : id ? (
								"Aanpassen"
							) : (
								"Toevoegen"
							)}
						</div>
						{id ? (
							<div
								onClick={handleDelete}
								className="btn btn-transparent"
							>
								Verwijderen
							</div>
						) : (
							false
						)}
					</div>
				</>
			}
		>
			<Card span={2}>
				<Options
					label="Koppeling type"
					onChange={(value) => setType(value)}
					options={TYPES}
					value={TYPES.find((i) => i.value == type)}
				/>
				{type === "lef" && (
					<Input
						onChange={(e) => setAccount(e.target.value)}
						value={account}
						type="text"
						placeholder="Account nummer, geleverd door Lef"
						label="Accountnumber"
					/>
				)}
				<Input
					onChange={(e) => setUsername(e.target.value)}
					value={username}
					type="text"
					placeholder="username"
					label="Username"
				/>
				<Input
					onChange={(e) => setPassword(e.target.value)}
					value={password}
					type="password"
					placeholder="Wachtwoord"
					label="Wachtwoord"
				/>
			</Card>
		</Layout>
	);
};

const mapStateToProps = (state) => ({
	user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateView);
