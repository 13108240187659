import React, { Component } from "react";
export default class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  render() {
    let { open } = this.state;
    const { options, onSelect, value } = this.props;
    const setOpen = val => this.setState({ open: val });
    return (
      <div className="options">
        <div onClick={() => setOpen(!open)} className="options__head">
          <p>{options[value].label}</p>
          <i className={`fal fa-angle-${open ? "up" : "down"}`} />
        </div>
        <div className={`options__body ${open ? "open" : ""}`}>
          {options &&
            options.map(({ label }, index) => (
              <div
                onClick={() => {
                  onSelect(index);
                  setOpen(false);
                }}
                key={label}
                className="option"
              >
                <p>{label || ""}</p>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

// function Select({ options, onSelect, value }) {
//   const [open, setOpen] = useState(false);
//   return (
//     <div className="options">
//       <div onClick={() => setOpen(!open)} className="options__head">
//         <p>{options[value].label}</p>
//         <i className={`fal fa-angle-${open ? "up" : "down"}`} />
//       </div>
//       <div className={`options__body ${open ? "open" : ""}`}>
//         {options &&
//           options.map(({ label }, index) => (
//             <div
//               onClick={() => {
//                 onSelect(index);
//                 setOpen(false);
//               }}
//               key={label}
//               className="option"
//             >
//               <p>{label || ""}</p>
//             </div>
//           ))}
//       </div>
//     </div>
//   );
// }

// export default Select;
