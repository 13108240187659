import React, { Component } from "react";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  CompositeDecorator,
  convertToRaw,
  getDefaultKeyBinding
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default class MyEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: EditorState.createEmpty()
    };
  }

  componentDidMount() {
    if (this.props.value) this.load();
  }

  load = () => {
    const { value } = this.props;
    let contentState = stateFromHTML(value);
    this.setState({
      value: EditorState.createWithContent(contentState)
    });
  };

  save = () => {
    let value = stateToHTML(this.state.value.getCurrentContent());
    return value;
  };

  onEditorStateChange = value => {
    this.setState({
      value
    });
    this.props.onChange(this.props.name, this.save());
  };

  render() {
    const { value } = this.state;
    return (
      <div className="editor">
        <Editor
          editorState={value}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}
