import React, { Component } from "react";

export default class Loading extends Component {
  render() {
    return (
      <div className="loading">
        <img src={require("../assets/logo.png")} alt="" />
        <i className="fal fa-spin fa-spinner-third" />
      </div>
    );
  }
}
