import React, { useState, useEffect } from "react";
import moment from "moment";
import Layout from "../../components/Layout";
import Table from "../../components/Table";
import axios from "axios";

const IndexView = props => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		axios
			.get("/connection")
			.then(res => {
				setData(res.data);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	}, []);

	return (
		<Layout>
			<div className="layout__head">
				<h1>Koppelingen</h1>
				<div className="buttons">
					<div
						onClick={() =>
							props.history.push("/connections/create")
						}
						className="btn btn-primary"
					>
						Nieuwe aanmaken
					</div>
				</div>
			</div>
			<Table
				emptyMessage="U heeft nog geen koppelingen"
				emptyIcon="fa-link"
				labels={["Type", "Username", "Aangemaakt op", ""]}
				data={data}
				loading={loading}
				renderItem={item => (
					<div key={item.id + "connection"} className="table__row">
						<div className="table__item">{item.type}</div>
						<div className="table__item">{item.username}</div>
						<div className="table__item">
							{moment(item.created_at).format("DD-MM-YYYY")}
						</div>
						<div
							onClick={() =>
								props.history.push(
									`/connections/${item.id}/edit`
								)
							}
							className="table__item"
						>
							<i
								style={{ cursor: "pointer" }}
								className="fal fa-pencil"
							></i>
						</div>
					</div>
				)}
			/>
		</Layout>
	);
};

export default IndexView;
