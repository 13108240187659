import React, { Component } from "react";
import { connect } from "react-redux";
import Layout from "../../components/Layout";
import ResetPassword from "../../components/forms/ResetPassword";
import EditUser from "../../components/forms/EditUser";

export class ShowView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      repeat: ""
    };
  }

  render() {
    let user = this.props.user.data;
    return (
      <Layout>
        <div className="layout__head">
          <h1>{user.fullname}</h1>
        </div>
        <div className="row-3">
          <EditUser />
          <ResetPassword />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowView);
