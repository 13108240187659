import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "./Loading";
import NavItem from "./NavItem";
import Powered from "./Calculator/Powered";

class Layout extends Component {
    constructor() {
        super();
        this.state = {
            visible: false
        };
    }

    toggle = () => {
        this.setState({
            visible: !this.state.visible
        });
    };

    logout = e => {
        localStorage.setItem("t", undefined);
    };

    render() {
        let { visible } = this.state;
        let { loading, title } = this.props;
        let user = this.props.user.data;
        return (
            <div className={`${visible && "open"} wrapper`}>
                <div className="side">
                    {!user.admin && (
                        <>
                            <NavItem
                                label="Dashboard"
                                to="/dashboard"
                                icon="fa-home"
                            />
                            <NavItem
                                label="Aanpassen"
                                to="/customize"
                                icon="fa-sliders-v"
                            />
                            <NavItem
                                label="Koppelingen"
                                to="/connections"
                                icon="fa-link"
                            />
                        </>
                    )}
                    {user.admin ? (
                        <>
                            <NavItem
                                label="Relaties"
                                to="/relations"
                                icon="fa-users"
                            />
                            <NavItem
                                label="Gebruikers"
                                to="/users"
                                icon="fa-user"
                            />
                        </>
                    ) : (
                        false
                    )}

                    <NavItem
                        label="Aanvragen"
                        to="/requests"
                        icon="fa-envelope"
                    />

                    {user.admin ? (
                        <NavItem
                            label="Instellingen"
                            icon="fa-sliders-v"
                            to="/settings"
                        />
                    ) : (
                        false
                    )}
                    <div className="sign-out">
                        <NavItem
                            label="Profiel"
                            to="/profile"
                            icon="fa-user-circle"
                        />

                        <NavItem
                            label="Uitloggen"
                            onClick={this.logout}
                            to="/login"
                            icon="fal fa-sign-out-alt"
                        />
                    </div>
                </div>
                <div className="layout">
                    <div className="layout__body">
                        {loading ? (
                            <div className="panel panel__loading">
                                <Loading />
                                <p className="description">
                                    Een ogenblik geduld aub
                                </p>
                            </div>
                        ) : (
                            <>
                                {title ? (
                                    <div className="layout__head">{title}</div>
                                ) : (
                                    false
                                )}
                                {this.props.children}
                            </>
                        )}
                        <div className="logo">
                            <Powered />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
