import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class NavItem extends Component {
    render() {
        let { to, icon, label } = this.props;
        return (
            <NavLink activeClassName="is-active" to={to}>
                <i className={`fal ${icon}`} />
                <span>{label}</span>
            </NavLink>
        );
    }
}
