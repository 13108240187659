import React, { Component } from "react";
import { Document, Page } from "react-pdf";
export default class PdfView extends Component {
  render() {
    return (
      <div>
        <Document file={require("../../assets/instructions.pdf")} />
      </div>
    );
  }
}
