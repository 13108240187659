import React, { Component } from "react";
import Loading from "../../components/Loading";

export default class IntroView extends Component {
    render() {
        return (
            <div className="view__intro">
                <Loading />
            </div>
        );
    }
}
