import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";

import SyntaxHighlighter from "react-syntax-highlighter";
import Layout from "../../components/Layout";
import { getUser } from "../../redux/user";
import Card from "../../components/Card";
import WidgetBuilder from "../../components/WidgetBuilder";

import pdf from "../../assets/instructions.pdf";

export class DashboardView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			relation: [],
			code: null,
			colors: {
				primary: "#3b2774",
				secondary: "#4bbcbc",
				gray: "#ebe7f1",
				text: "#000",
			},
		};
	}

	async componentDidMount() {
		let user = await this.props.getUser();
		let { relation } = user;
		if (relation.length) {
			relation = relation[0];
			this.setState({
				colors: {
					primary: relation.primary,
					secondary: relation.secondary,
					gray: relation.gray,
					text: relation.text,
				},
			});
		}

		this.setState({
			loading: false,
			...user,
		});
	}

	onSave = async (colors) => {
		this.setState({ loading: true });
		let { relation } = this.state;
		relation = relation[0];
		relation.colors = colors;
		let { data } = await axios.patch(`/relations/${relation.id}`, relation);

		this.setState({
			colors,
			loading: false,
		});
	};

	render() {
		let { loading, relation, colors } = this.state;
		let button = "";
		let widget = "";
		let token = "";
		let script = "";
		let soloButton = `<p 
  data-price="10000"
  data-before="€" 
  data-after="p/m" 
  class="ag-solo-button"
></p>
`;

		if (relation.length) {
			relation = relation[0];
			token = relation.token;
			script = `
<script>
  function init(){
  let e="${window.location.origin}";
  t=document.createElement("script");
  t.src=e+"/dist/bundle.js";
  t.onload=function(){AutoGeld.selector="#calculator";
  AutoGeld.token="${token}";
  AutoGeld.theme = {
        primary: '${colors.primary}',
        secondary: '${colors.secondary}',
        gray: '${colors.gray}',
        text: '${colors.text}'
    }
  AutoGeld.show()
  };
  let d=document.createElement("link");
  d.rel="stylesheet"; d.href=e+"/css/style.css";
  document.body.appendChild(t); document.body.appendChild(d);}
  init();
</script>
      `;
			widget = `<div id="calculator"></div>`;

			button = `<div data-price="" data-license_plate="" data-model="" data-thumbnail="" class="ag-button">
  Vraag offerte aan
</div>`;
		}
		return (
			<Layout {...{ loading }}>
				<div className="row-3 dashboard">
					<Card span={3}>
						<WidgetBuilder
							token={token}
							colors={colors}
							onSave={this.onSave}
						/>

						{/* <p className="card__title">Installatie</p>

            <Step count={1}>
              <p className="description">
                Voeg de onderstaande code toe aan uw site
              </p>
              <SyntaxHighlighter
                wrapLines={true}
                codeTagProps={{
                  style: {
                    width: 200
                  }
                }}
              >
                {widget}
              </SyntaxHighlighter>
            </Step>

            <Step count={2}>
              <p className="description">
                Plaats dit stukje html bij een product
              </p>
              <SyntaxHighlighter>{button}</SyntaxHighlighter>
            </Step> */}
					</Card>
					<Card span={3}>
						<div>
							<p>Plaats dit script in de footer</p>
							<SyntaxHighlighter
								wrapLines={true}
								codeTagProps={{
									style: {
										width: 200,
									},
								}}
							>
								{script}
							</SyntaxHighlighter>
						</div>
						<div>
							<p>Plaats deze div waar u de widget wilt hebben</p>
							<SyntaxHighlighter
								wrapLines={true}
								codeTagProps={{
									style: {
										width: 200,
									},
								}}
							>
								{widget}
							</SyntaxHighlighter>
						</div>

						<div>
							<p>Knop om het formulier te openen</p>
							<SyntaxHighlighter>{button}</SyntaxHighlighter>
						</div>
						<div>
							<p>
								Stuk tekst om alleen het maandtermijn te tonen
							</p>
							<SyntaxHighlighter>{soloButton}</SyntaxHighlighter>
						</div>
					</Card>
					<Card span={3}>
						<p>
							Nog niet helemaal duidelijk? Bekijk dan{" "}
							<a style={{ color: "#3b2774" }} href={pdf}>
								hier
							</a>{" "}
							onze handleiding
						</p>
					</Card>
				</div>
			</Layout>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getUser: bindActionCreators(getUser, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
