import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import Layout from "../../components/Layout";

export class ShowView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      media: []
    };
  }

  componentDidMount() {
    this.fetchRequest();
  }

  fetchRequest = async () => {
    this.setState({ loading: true });
    let { id } = this.props.match.params;
    let { data } = await axios.get(`/requests/${id}`);
    this.setState({
      ...data,
      loading: false
    });
  };

  render() {
    let {
      loading,
      fullname,
      first_name,
      affix,
      last_name,
      email,
      media,
      street,
      city,
      zipcode,
      thumbnail,
      model,
      price,
      duration,
      slot,
      price_month,
      license_plate,
      telephone
    } = this.state;
    return (
      <Layout {...loading}>
        <div className="layout__head">
          <h1>{fullname}</h1>
        </div>
        <div className="row-3">
          <div className="card  span-2">
            <p className="card__title">Contact gegevens</p>
            <div className="info">
              <p className="label">Voornaam</p>
              <p>{first_name}</p>
            </div>
            <div className="info">
              <p className="label">Tussenvoegsel</p>
              <p>{affix}</p>
            </div>
            <div className="info">
              <p className="label">Achternaam</p>
              <p>{last_name}</p>
            </div>
            <div className="info">
              <p className="label">E-mail</p>
              <p>{email}</p>
            </div>
            <div className="info">
              <p className="label">Telefoonnummer</p>
              <p>{telephone}</p>
            </div>
          </div>
          <div className="card card-map car-preview">
            <p className="card__title">Auto</p>
            {thumbnail ? <img src={thumbnail} /> : false}
            <div className="info">
              <p className="label">Model</p>
              <p className="model">{model || "Geen model meegestuurd"}</p>
            </div>
            <div className="info">
              <p className="label">Kenteken</p>
              <p className="model">
                {license_plate || "Geen kenteken meegestuurd"}
              </p>
            </div>
            <div className="info">
              <p className="label">Prijs</p>
              <p className="price">
                {price
                  ? `€ ${Number(price).toFixed(2)}`
                  : "Geen prijs meegestuurd"}
              </p>
            </div>
            <div className="info">
              <p className="label">Looptijd</p>
              <p className="duration">
                {duration ? `${duration} maanden` : "Geen looptijd meegestuurd"}
              </p>
            </div>
            <div className="info">
              <p className="label">Slottermijn</p>
              <p className="duration">
                {slot
                  ? `€ ${Number(slot).toFixed(2)}`
                  : "Geen slottermijn meegestuurd"}
              </p>
            </div>
            <div className="info">
              <p className="label">Berekende prijs per maand</p>
              <p className="duration">
                {slot
                  ? `€ ${Number(price_month).toFixed(2)}`
                  : "Geen berekening meegestuurd"}
              </p>
            </div>
          </div>
          {/* <div className="card span-2">
            <p className="card__title">Bestanden</p>

            <div className="media">
              {media.length
                ? media.map(media => (
                    <a
                      key={media.url}
                      href={media.url}
                      target="_blank"
                      className="media__item"
                    >
                      {media.extension === "pdf" ? (
                        <i className="fal fa-file-pdf" />
                      ) : (
                        <i className="fal fa-image" />
                      )}
                      <p>{media.filename}</p>
                    </a>
                  ))
                : false}
            </div>
          </div> */}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowView);
