import React, { Component } from "react";

export default class ListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDragging: false
    };
  }

  onDragStart = () => {
    this.setState({
      isDragging: true
    });
    this.props.onDragStart();
  };

  onDragEnd = () => {
    this.setState({
      isDragging: false
    });
    this.props.onDrop();
  };

  render() {
    let { isDragging } = this.state;
    const { label, icon } = this.props;
    return (
      <>
        <div
          draggable
          onDrop={
            this.props.onDrop
              ? () => this.props.onDrop
              : () => console.log("Dropped")
          }
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
          className={`draggable__list__item ${isDragging ? "is-dragging" : ""}`}
        >
          <span>{label || ""}</span>
          <div className="icon">
            <i className={`fal ${icon}`}></i>
          </div>
        </div>
      </>
    );
  }
}
