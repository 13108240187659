import React from "react";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";

const Card = ({
    title,
    show,
    edit,
    domain,
    children,
    loading,
    span,
    buttons
}) => (
    <div
        className={`card ${loading && "loading"} ${span ? `span-${span}` : ""}`}
    >
        {loading ? (
            <div className="loading__view">
                <i className="fal fa-spin fa-spinner-third" />
            </div>
        ) : (
            false
        )}
        {children ? (
            children
        ) : (
            <>
                <h1>{title || ""}</h1>
                <h3 className="domain">{domain || ""}</h3>
                <div className="buttons">
                    {buttons ? buttons : false}
                    {show ? (
                        <NavLink to={show}>
                            <i className="fal fa-eye" />
                        </NavLink>
                    ) : (
                        false
                    )}

                    <NavLink to={edit || "/dashboard"}>
                        <i className="fal fa-pencil" />
                    </NavLink>
                </div>
            </>
        )}
    </div>
);
export default Card;
