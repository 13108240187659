import React from "react";
import Box from "./Box";

export default function Calculator({ columns, colors, price }) {
	return (
		<div
			style={{
				background: colors.gray,
			}}
			className="calculator"
		>
			{columns
				? Object.keys(columns).map((key, index) =>
						columns[key] ? (
							<Box
								colors={colors}
								{...columns[key]}
								price={price}
							/>
						) : (
							false
						)
				  )
				: false}
		</div>
	);
}

// class Calculator extends Component {
//   constructor(props) {
//     super(props);

//     this.state = { loading: true, texts: {} };
//   }

//   render() {
//     let {
//       columns,

//       admin,
//       handleColumn
//     } = this.props;
//     if (loading) {
//       return <Loading />;
//     }
//     return (
//       <div className="calculator">
//         {admin ? (
//           <CheckboxCard
//             visible={has_column_1}
//             onChange={val => handleColumn("has_column_1", val)}
//           >
//             <One text={financial} />
//           </CheckboxCard>
//         ) : has_column_1 ? (
//           <One text={financial} visible={has_column_1} />
//         ) : (
//           false
//         )}
//         {admin ? (
//           <CheckboxCard
//             visible={has_column_2}
//             onChange={val => handleColumn("has_column_2", val)}
//           >
//             <Two />
//           </CheckboxCard>
//         ) : has_column_2 ? (
//           <Two visible={has_column_2} />
//         ) : (
//           false
//         )}
//         {admin ? (
//           <CheckboxCard
//             visible={has_column_3}
//             onChange={val => handleColumn("has_column_3", val)}
//           >
//             <Three text={operational} />
//           </CheckboxCard>
//         ) : has_column_3 ? (
//           <Three text={operational} visible={has_column_3} />
//         ) : (
//           false
//         )}
//       </div>
//     );
//   }
// }

// export default Calculator;
