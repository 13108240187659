import React from "react";

const Powered = () => (
  <a target="_blank" href="https://www.autogeld.nl" className="powered">
    <img src={require("../../assets/logo.png")} alt="" />
    <div className="contact">
      <div className="contact__wrapper">
        <div className="contact__item">
          <div className="icon">
            <i className="fal fa-envelope"></i>
          </div>
          <span>info@autogeld.nl</span>
        </div>
        <div className="contact__item">
          <div className="icon">
            <i className="fal fa-phone"></i>
          </div>

          <span>030 - 6880061</span>
        </div>
      </div>
    </div>
  </a>
);

export default Powered;
