import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loading from "../../components/Loading";
import { getUser, loginUser } from "../../redux/user";

class LoginView extends Component {
    constructor() {
        super();

        this.state = {
            email: "",
            password: "",
            loading: true
        };
    }

    async componentDidMount() {
        let user = await this.props.getUser();

        if (user) {
            // let to = "/requests";
            // if (!user.admin) {
            //     to = "/dashboard";
            // }
            // //   localStorage.activePage = to;
            // this.props.history.push(to);
            this.setState({
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    handleSubmit = async e => {
        e.preventDefault();
        let { email, password } = this.state;

        if (!email || !password) {
            return;
        }
        this.setState({
            loading: true
        });

        let response;
        try {
            response = await this.props.loginUser(email, password);

            if (response.id) {
                let to = "/requests";
                localStorage.activePage = to;

                // let user = await this.props.getUser();

                this.props.history.push(to);
            }
        } catch (error) {
            alert("Something went wrong");
            this.setState({
                loading: false
            });
        }

        this.setState({
            loading: false
        });
    };

    register = () => {
        this.props.history.push("/register");
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        let { email, password, loading } = this.state;
        if (loading) {
            return (
                <div className="login__view">
                    <Loading />
                </div>
            );
        }
        return (
            <div className="login__view">
                <form
                    ref={r => (this.form = r)}
                    onSubmit={this.handleSubmit}
                    className="form"
                >
                    <img src={require("../../assets/logo.png")} alt="" />
                    <input
                        value={email}
                        type="email"
                        placeholder="E-mail"
                        name="email"
                        onChange={this.handleChange}
                    />
                    <input
                        value={password}
                        type="password"
                        placeholder="Password"
                        name="password"
                        onChange={this.handleChange}
                    />
                    <div className="buttons">
                        <button
                            onClick={this.handleSubmit}
                            className="btn btn-primary"
                        >
                            Signin
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getUser: bindActionCreators(getUser, dispatch),
        loginUser: bindActionCreators(loginUser, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
