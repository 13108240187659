import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export default class ResetPasswordView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      repeat: ""
    };
  }

  handleSubmit = async e => {
    let { password, repeat } = this.state;
    e.preventDefault();
    if (password !== repeat) {
      toast.info(`Wachtwoorden komen niet overeen`, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      this.setState({ password: "", repeat: "" });
      return;
    }
    try {
      let { token } = this.props.match.params;

      let { data } = await axios.post("/auth/change", {
        password,
        token
      });
      if (data.token) {
        localStorage.t = data.token;
      }

      let to = "/dashboard";
      localStorage.activePage = to;
      this.props.history.push(to);
    } catch (error) {
      toast.info(`Er is iets misgegaan, probeer het opnieuw`, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    let { password, repeat } = this.state;
    return (
      <div className="login__view">
        <ToastContainer />
        <form
          ref={r => (this.form = r)}
          onSubmit={this.handleSubmit}
          className="form"
        >
          <h1>Wachtwoord aanpassen</h1>
          <input
            value={password}
            type="password"
            placeholder="Wachtwoord"
            name="password"
            onChange={this.handleChange}
          />
          <input
            value={repeat}
            type="password"
            placeholder="Herhaal wachtwoord"
            name="repeat"
            onChange={this.handleChange}
          />
          <div className="buttons">
            <button onClick={this.handleSubmit} className="btn btn-primary">
              Opslaan
            </button>
          </div>
        </form>
      </div>
    );
  }
}
