import React, { Component } from "react";
import Input from "../Input";
import axios from "axios";

export default class AddText extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			key: "",
		};
	}

	save = async () => {
		let { key } = this.state;

		this.setState({
			loading: true,
		});
		if (!key) {
			return;
		}

		try {
			await axios.post("/text", {
				texts: {
					[key]: "",
				},
			});
			this.setState({
				loading: false,
				label: "",
			});
			this.props.onSave();
		} catch (error) {
			this.setState({
				loading: false,
			});
		}
	};

	render() {
		const { key } = this.state;
		return (
			<div>
				<Input
					label="Blok naam"
					onChange={(e) => this.setState({ key: e.target.value })}
					value={key || ""}
				/>
				<div onClick={() => this.save()} className="btn btn-primary">
					Aanmaken
				</div>
			</div>
		);
	}
}
