import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import user from "./user";

const rootReducer = combineReducers({
  user
});

export default function configureStore() {
  let store = createStore(rootReducer, applyMiddleware(thunk));
  return store;
}
