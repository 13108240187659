import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast, ToastContainer } from "react-toastify";

import Card from "../Card";
import Row from "../Row";

import Input from "../Input";

import { updateUser } from "../../redux/user";

export class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: {
        ...props.user.data
      }
    };
  }

  handleChange = e => {
    let { user } = this.state;
    user[e.target.name] = e.target.value;
    this.setState({
      user
    });
  };

  save = async () => {
    this.setState({ loading: true });
    let { user } = this.state;

    let data = await this.props.updateUser(user);
    if (data) {
      toast.info(`Succesvol aangepast`, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      this.setState({ loading: false });
      return;
    }
    toast.warning("Er is iets misgegaan", {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    this.setState({ loading: false });
  };

  render() {
    let { loading, user } = this.state;
    let { first_name, affix, last_name } = user;
    return (
      <Card {...{ loading }} span={2}>
        <p className="card__title">Profiel aanpassen</p>
        <Input
          label="Voornaam"
          type="text"
          placeholder="Voornaam"
          name="first_name"
          value={first_name}
          onChange={this.handleChange}
        />
        <Input
          label="Tussenvoegsel"
          type="text"
          placeholder="Tussenvoegsel"
          name="affix"
          value={affix}
          onChange={this.handleChange}
        />
        <Input
          label="Achternaam"
          type="text"
          placeholder="Achternaam"
          name="last_name"
          value={last_name}
          onChange={this.handleChange}
        />
        <div className="buttons">
          <button onClick={this.save} className="btn btn-primary">
            Aanpassen
          </button>
        </div>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUser: bindActionCreators(updateUser, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUser);
