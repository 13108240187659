import React from "react";
import CalculatorBox from "./Columns/CalculatorBox";
export default function Box({ type, value, colors, price }) {
	if (type === "calculator") {
		return <CalculatorBox colors={colors} price={price} />;
	}
	let style = {};
	if (colors.primary) {
		style.color = colors.text;
	}
	return (
		<div
			style={style}
			dangerouslySetInnerHTML={{ __html: value }}
			className="calculator__box"
		/>
	);
}
