import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";

import Layout from "../../components/Layout";
import Card from "../../components/Card";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";

export class IndexView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: [],
            page: 1
        };
    }

    componentDidMount() {
        this.fetchRequests();
    }

    fetchRequests = async () => {
        this.setState({ loading: true });
        let { page } = this.state;
        let { data } = await axios.get(`/requests?page=${page}`);
        this.setState({
            ...data,
            loading: false
        });
    };

    render() {
        let { data, page, total, lastPage, loading } = this.state;
        return (
            <Layout>
                <Table
                    emptyMessage="U heeft nog aanvragen"
                    emptyIcon="fa-inbox"
                    labels={[
                        "Naam",
                        "E-mail",
                        "Telefoonnummer",
                        "Aanvraagdatum",
                        ""
                    ]}
                    data={data}
                    loading={loading}
                    renderItem={item => (
                        <div
                            key={item.id + item.fullname}
                            className="table__row"
                        >
                            <div className="table__item">{item.fullname}</div>
                            <div className="table__item">{item.email}</div>
                            <div className="table__item">{item.telephone}</div>
                            <div className="table__item">
                                {moment(item.created_at)
                                    .format("DD MM YYYY")
                                    .split(" ")
                                    .join("-")}
                            </div>

                            <div className="table__item">
                                <NavLink to={`/requests/${item.id}`}>
                                    <i className="fal fa-eye" />
                                </NavLink>
                            </div>
                        </div>
                    )}
                />
                <Pagination
                    last_page={lastPage}
                    current_page={page}
                    total={total}
                    prev={() =>
                        this.setState({ page: page - 1 }, () =>
                            this.fetchRequests()
                        )
                    }
                    next={() =>
                        this.setState({ page: page + 1 }, () =>
                            this.fetchRequests()
                        )
                    }
                />
            </Layout>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(IndexView);
