import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Layout from "../../components/Layout";
import Card from "../../components/Card";
import { toast, ToastContainer } from "react-toastify";

export class IndexView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      search: ""
    };
  }
  async componentDidMount() {
    this.fetchRelations();
  }
  fetchRelations = async (page = 1) => {
    this.setState({ loading: true });

    let { data } = await axios.get("/relations");
    this.setState({
      data,
      loading: false
    });
  };

  delete = async (id, e) => {
    e.preventDefault();
    if (window.confirm("Weet je zeker dat je deze relatie wilt verwijderen?")) {
      try {
        let result = await axios.delete(`/relations/${id}`);
        if (result.data.message) {
          let { data } = this.state;
          data = data.filter(i => i.id !== id);
          this.setState({
            data
          });
        }
      } catch (error) {
        toast.error(`Er ging iets mis, probeer het later opnieuw`, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    }
  };

  render() {
    let { loading, data, search } = this.state;
    data = data.filter(item =>
      item.company.toLowerCase().includes(search.toLowerCase())
    );
    return (
      <Layout loading={loading}>
        <ToastContainer />
        <div className="layout__head">
          <span
            style={{ paddingLeft: 0 }}
            className="btn btn-transparent"
            onClick={() => this.props.history.push("/relations/create")}
          >
            Relatie toevoegen
            <i className="fal fa-plus" />
          </span>
          <input
            value={search}
            type="text"
            onChange={e => this.setState({ search: e.target.value })}
            placeholder="Zoek relaties.."
          />
        </div>
        <div className="row-3">
          {data.map(({ id, company, domain }) => (
            <Card
              title={company}
              key={company + id}
              edit={`/relations/${id}/edit`}
              buttons={
                <a href="" onClick={e => this.delete(id, e)}>
                  <i className="fal fa-trash" />
                </a>
              }
              domain={domain}
            />
          ))}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexView);
