import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";

import Layout from "../../../components/Layout";
import Input from "../../../components/Input";

export class CreateView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      loading: true,
      user: {
        password: null
      }
    };
  }

  componentDidMount() {
    this.fetchRelation(this.props.match.params.id);
  }

  fetchRelation = async id => {
    this.setState({
      loading: true
    });

    let { data } = await axios.get(`/relations/${id}`);

    this.setState({
      ...data,
      loading: false
    });
  };

  hanldeInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUser = e => {
    let { user } = this.state;
    user[e.target.name] = e.target.value;
    this.setState({ user });
  };

  createUser = async () => {
    this.setState({
      loading: true
    });
    let { user, company } = this.state;
    let { id } = this.props.match.params;
    try {
      let { data } = await axios.post("/auth/register", user);
      if (data.id) {
        await axios.post(`/relations/${id}/user`, { user_id: data.id });
        this.setState({
          user: {},
          users: [...this.state.users, data],
          loading: false
        });
        toast.info(`${data.fullname} succesvol toegevoegd aan ${company}`, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    } catch (error) {
      toast.info(`Er ging iets mis`, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }

    this.setState({
      user: {
        password: this.state.user.password
      },
      loading: false
    });
  };

  render() {
    let { loading, user } = this.state;
    let { id } = this.props.match.params;

    return (
      <Layout loading={loading}>
        <ToastContainer />
        <div className="layout__head">
          <h1>Gebruiker toevoegen</h1>
          <div onClick={this.createUser} className="btn btn-primary">
            Toevoegen
          </div>
        </div>
        <div className="row-3">
          {id ? (
            <div className="card span-2">
              <p className="card__title">Voeg gebruiker toe</p>
              <Input
                label="Voornaam"
                name="first_name"
                value={user.first_name}
                onChange={this.handleUser}
                type="text"
              />
              <Input
                label="Tussenvoegsel"
                name="affix"
                value={user.affix}
                onChange={this.handleUser}
                type="text"
              />
              <Input
                label="Achternaam"
                value={user.last_name}
                name="last_name"
                onChange={this.handleUser}
                type="text"
              />
              <Input
                label="E-mail"
                value={user.email}
                name="email"
                onChange={this.handleUser}
                type="text"
              />
            </div>
          ) : (
            false
          )}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateView);
