import React, { useState } from "react";

const Dropzone = ({
  children,
  onDrop,
  onDragOver,
  disable,
  active,
  loading
}) => {
  return (
    <div
      onDragOver={() => {
        if (onDragOver) {
          onDragOver();
        }
      }}
      onDragLeave={e => {
        if (onDrop) {
          onDrop();
        }
      }}
      className="dropzone"
    >
      {active ? (
        <div className="hover-helper">
          {loading ? (
            <i className="fal fa-spin fa-spinner-third"></i>
          ) : (
            <p>Laat los om uw blokken toetevoegen.</p>
          )}
        </div>
      ) : (
        false
      )}
      {children}
    </div>
  );
};

export default Dropzone;
