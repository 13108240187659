import React from "react";

const Table = ({
    data,
    labels,
    renderItem,
    loading,
    emptyMessage,
    emptyIcon
}) => {
    return (
        <div className="table">
            <div className="table__head">
                {labels.map(label => (
                    <div key={label} className="table__item">
                        {label}
                    </div>
                ))}
            </div>
            <div className={`table__body ${loading && "loading"}`}>
                {loading ? (
                    <i className="fa-spin fal fa-spinner-third" />
                ) : data.length ? (
                    data.map(renderItem)
                ) : (
                    <div className="table__empty">
                        <i
                            className={`fal ${emptyIcon ||
                                "fa-transporter-empty"}`}
                        ></i>
                        <p>{emptyMessage || ""}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Table;
