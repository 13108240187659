import React from "react";

export default function UserList({ users }) {
  return (
    <div>
      {users && users.length
        ? users.map(user => <p key={user.fullname}>{user.fullname}</p>)
        : false}
    </div>
  );
}
