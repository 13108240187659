import React, { Component } from "react";
import axios from "axios";
import queryString from "query-string";
import Calculator from "../components/Calculator";
export default class IframeView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			price: undefined,
			settings: {},
			colors: {
				primary: "#3b2774",
				secondary: "#4bbcbc",
				gray: "#ebe7f1",
				text: "#000",
			},
		};
	}

	componentDidMount() {
		let { colors } = this.state;
		const values = queryString.parse(this.props.location.search);

		const price = values.price;
		colors.primary = values.primary || colors.primary;
		colors.gray = values.gray || colors.gray;
		colors.secondary = values.secondary || colors.secondary;
		colors.text = values.text || colors.text;

		this.getSettings(values.token);
		this.setState({
			colors,
			token: values.token,
			price,
		});
	}

	getSettings = async (token) => {
		this.setState({ loading: true });
		// let { id } = this.props.match.params;

		let { data } = await axios.get(`/calculator`, {
			params: { token: window.atob(token) },
		});
		this.setState({
			settings: data,
			loading: true,
		});
	};

	render() {
		let { loading, settings, colors, price } = this.state;

		return (
			<Calculator
				{...{
					...settings,
					colors,
					loading,
					price,
				}}
			/>
		);
	}
}
