import React, { useState } from "react";

const Input = props => {
  const [focus, setfocus] = useState(false);
  return (
    <div className={`input-wrapper ${focus ? "focussed" : ""}`}>
      {props.label ? <p className="label">{props.label}</p> : false}
      <input
        {...props}
        onFocus={() => setfocus(true)}
        onBlur={() => setfocus(false)}
        onChange={e => {
          if (props.type === "number") {
            if (Number(e.target.value !== NaN)) {
              props.onChange(e);
            } else {
              e.target.value = props.value;
              props.onChange(e);
            }
          } else {
            props.onChange(e);
          }
        }}
        value={props.value || ""}
      />
    </div>
  );
};

export default Input;
