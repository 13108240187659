import React from "react";

const Options = ({ options, value, onChange, label }) => {
    let defaultValue = value && value.value ? value.value : "";
    return (
        <div className="options">
            <div className="options-label">{label || ""}</div>
            <div className="options-body">
                {options.map(item => (
                    <div
                        onClick={() => onChange(item.value)}
                        key={item.value}
                        className={`option ${item.value == defaultValue &&
                            "option-active"}`}
                    >
                        {item.label}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Options;
