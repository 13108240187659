import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";

import Layout from "../../components/Layout";
import Checkbox from "../../components/Checkbox";
import UserList from "../../components/UserList";
import Grid from "../../components/Grid";
import Box from "../../components/Calculator/Box";
import ListItem from "../../components/Drag/ListItem";
import Dropzone from "../../components/Drag/Dropzone";

export class CreateView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: null,
			loading: true,
			users: [],
			user: {},
			dragging: null,
			texts: {},
			over: null,
		};
	}

	componentDidMount() {
		let { id } = this.props.match.params;
		this.setState({
			id,
		});
		if (id) {
			this.fetchRelation(id);
			this.fetchTexts();
		} else {
			let relation = this.props.user.data.relation[0];
			if (relation) {
				this.setState({
					id: relation.id,
				});
				this.fetchRelation(relation.id);
			}

			this.fetchTexts();

			this.setState({
				loading: false,
			});
		}
	}

	fetchTexts = async () => {
		let { data } = await axios.get("/text");
		this.setState({
			texts: data,
			loading: false,
		});
	};

	fetchRelation = async (id) => {
		this.setState({
			loading: true,
		});

		let { data } = await axios.get(`/relations/${id}`);
		this.setState({
			...data,
			loading: false,
		});
	};

	hanldeInput = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	save = async () => {
		let { id } = this.state;
		this.setState({
			loading: true,
		});
		let relation = {
			company: this.state.company,
			domain: this.state.domain,
			column_1: this.state.column_1,
			column_2: this.state.column_2,
			column_3: this.state.column_3,
		};

		if (id) {
			let { data } = await axios.patch(`/relations/${id}`, relation);
			if (data)
				toast.info(`${this.state.company} succesvol aangepast`, {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
		} else {
			let { data } = await axios.post(`/relations`, relation);

			if (data) this.props.history.push(`/relations/${data.id}/edit`);
			toast.info(`${this.state.company} succesvol toegevoegd`, {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
		}
		this.setState({
			loading: false,
		});
	};

	update = async () => {
		let { company, domain, id } = this.state;
		let { data } = await axios.patch(`/relations/${id}`, {
			company,
			domain,
		});

		this.setState({
			loading: false,
		});
	};

	updateColumn = async () => {
		this.setState({ loading: true });
		let { over, dragging, column_1, column_2, column_3, id } = this.state;
		let keys = ["column_1", "column_2", "column_3"];
		let params = { column_1, column_2, column_3 };
		params[keys[over]] = dragging;

		let { data } = await axios.patch(`/relations/${id}`, params);
		this.setState({
			loading: false,
			over: null,
			dragging: null,
			...data,
		});
	};

	setColumnNull = async (key) => {
		let { over, dragging, column_1, column_2, column_3, id } = this.state;
		let params = { column_1, column_2, column_3 };
		params[key] = null;
		let { data } = await axios.patch(`/relations/${id}`, params);
		this.setState({
			loading: false,
			over: null,
			dragging: null,
			...data,
		});
	};
	handleColumn = (key, value) => {
		this.setState({
			[key]: value,
		});
	};

	render() {
		let {
			loading,
			over,
			id,
			company,
			domain,
			dragging,
			users,
			texts,
			columns,
		} = this.state;
		let user = this.props.user.data;
		return (
			<Layout loading={loading && over === null}>
				<ToastContainer />
				<div className="layout__head">
					<h1>{id ? `${company} aanpassen` : "Relatie aanmaken"}</h1>
					<div className="buttons">
						{id && user.admin ? (
							<div
								onClick={() =>
									this.props.history.push(
										`/relations/${id}/edit/users`
									)
								}
								className="btn btn-transparent"
							>
								Gebruiker toevoegen
							</div>
						) : (
							false
						)}
						<div onClick={this.save} className="btn btn-primary">
							{id ? "Aanpassen" : "Toevoegen"}
						</div>
					</div>
				</div>

				<div className="row-3">
					{user.admin !== 0 ? (
						<div className="card">
							<p className="card__title">Bedrijfsgegevens</p>
							<input
								type="text"
								name="company"
								placeholder="Bedrijfsnaam"
								onChange={this.hanldeInput}
								value={company}
							/>
							<input
								type="text"
								name="domain"
								placeholder="Domein"
								onChange={this.hanldeInput}
								value={domain}
							/>
						</div>
					) : (
						false
					)}

					{id ? (
						<div className="card">
							<p className="card__title">Blokken</p>
							{texts.length &&
								texts.map((text) => (
									<ListItem
										key={text.id + text.key}
										onDrop={this.updateColumn}
										onDragStart={() =>
											this.setState({ dragging: text.id })
										}
										label={text.key}
										icon="fa-arrows-alt"
									/>
								))}
						</div>
					) : (
						false
					)}
					{id ? (
						<>
							<div className="card">
								<p className="card__title">Gebruikers</p>
								<UserList users={users} />
							</div>
							<div
								className="card span-3"
								style={{ borderColor: "transparent" }}
							>
								<p className="card__title">Configure</p>
								<div className="calculator">
									<Grid columns={3}>
										{columns
											? Object.keys(columns).map(
													(key, index) => (
														<Dropzone
															key={key + index}
															active={
																over === index
															}
															onDragOver={() =>
																this.setState({
																	over: index,
																})
															}
															loading={
																loading &&
																over === index
															}
														>
															{columns[key] ? (
																<>
																	<div
																		style={{
																			position:
																				"absolute",
																			right: 10,
																			top: 10,
																			zIndex: 66,
																		}}
																		onClick={() =>
																			this.setColumnNull(
																				key
																			)
																		}
																		className="btn"
																	>
																		<i
																			style={{
																				padding: 0,
																			}}
																			className="fal fa-trash"
																		></i>
																	</div>
																	<Box
																		colors={{
																			primary:
																				"#3b2774",
																			secondary:
																				"#4bbcbc",
																			gray:
																				"#ebe7f1",
																			text:
																				"#000",
																		}}
																		{...columns[
																			key
																		]}
																	/>
																</>
															) : (
																false
															)}
														</Dropzone>
													)
											  )
											: false}
									</Grid>
								</div>
							</div>
						</>
					) : (
						false
					)}
				</div>
			</Layout>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateView);
